<template>
  <div>
    <Header />
    <div class="body-recommend" style="padding-top: 150px">
      <!-- Tab Selection -->
      <div class="tab-selection flex">
        <ul>
          <li :class="active_class == 'recommend' ? 'active' : ''">
            <a :href="`/${i18n.locale}/recommend`"
              >{{ $t("home.upcoming-matches") }}
            </a>
          </li>
          <li :class="active_class == 'match-result' ? 'active' : ''">
            <a :href="`/${i18n.locale}/match-result`"
              >{{ $t("home.match-result") }}
            </a>
          </li>
        </ul>
      </div>
      <section class="main-container pb-5">
        <div class="container">
          <div class="content">
            <div class="container" v-if="bannerTop">
              <!-- Banner -->
              <div
                v-for="(banner, key) in bannerTop"
                :key="key"
                class="mt-2 mb-2"
              >
                <a :href="banner.target_url" target="_blank"
                  ><img style="width: 100%" :src="banner.path"
                /></a>
              </div>
            </div>
            <div class="container">
              <div class="container grid grid-2">
                <!-- Now -->
                <div class="container" v-if="dataMatchObj && dataMatchObj[0]">
                  <div class="title-bar">
                    <div class="title margin-right-auto">
                      {{ $t("home.now") }}
                    </div>
                  </div>
                  <div class="card-matches card-bg-grey">
                    <div class="card-matches-title">
                      {{ dataMatchObj[0]["league_name"] }}
                    </div>
                    <div class="card-matches-content grid grid-4-matches">
                      <div class="home-team text-center">
                        <img src="../assets/img/soccer.png" alt="" />
                        <span class="team-title">
                          {{ dataMatchObj[0]["home_team"] }}</span
                        >
                      </div>
                      <div class="result-live text-center">
                        <div class="live-time">
                          <a
                            href=""
                            class="
                              btn btn-block btn-sm btn-danger
                              font-weight-bold
                            "
                          >
                            {{ dataMatchObj[0]["open_date"] }}</a
                          >
                          <a
                            href=""
                            class="
                              btn btn-block btn-sm btn-live-time
                              font-weight-bold
                            "
                          >
                            {{ dataMatchObj[0]["open_time"] }}</a
                          >
                        </div>
                      </div>
                      <div class="away-team text-center">
                        <img src="../assets/img/soccer.png" alt="" />
                        <span class="team-title">
                          {{ dataMatchObj[0]["away_team"] }}</span
                        >
                      </div>
                      <div class="modal-button">
                        <a href="" class="btn btn-sm btn-block handicap"
                          >{{ $t("home.goal-hand") }}
                          {{ dataMatchObj[0]["handicap"] }}</a
                        >
                        <a
                          @click="toggleModal"
                          :data-id="0"
                          href=""
                          class="btn btn-sm btn-block btn-live-modal mt-2"
                          >{{ $t("home.unravel") }}
                        </a>
                        <a
                          @click="toggleModalReal"
                          :data-id="0"
                          href=""
                          class="btn btn-sm btn-block btn-live-modal mt-2"
                          >{{ $t("home.real-order") }}
                        </a>
                      </div>
                    </div>

                    <div class="card-matches-footer">
                      <div class="left">
                        <a
                          target="_blank"
                          :href="whatsappLink"
                          style="color: white"
                        >
                          {{ $t("nav.click") }}
                          <img
                            src="../assets/img/whatsapp_green.png"
                            alt=""
                            style="
                              width: 40px;
                              height: 40px;
                              display: inline-block;
                            "
                          />
                          {{ $t("nav.recom") }} :
                          <span class="hightlight-text">{{
                            dataMatchObj[0]["free_analysis"]
                          }}</span>
                        </a>
                      </div>
                      <div class="right win"></div>
                    </div>
                  </div>
                </div>
                <!-- Next -->
                <div class="container" v-if="dataMatchObj && dataMatchObj[1]">
                  <div class="title-bar">
                    <div class="title">{{ $t("home.next") }}</div>
                  </div>
                  <div class="card-matches-xs card-bg-grey">
                    <div class="card-matches-title">
                      {{ dataMatchObj[1]["league_name"] }}
                    </div>
                    <div class="card-matches-content grid grid-4-matches">
                      <div class="home-team text-center">
                        <img src="../assets/img/soccer.png" alt="" />
                        <span class="team-title">{{
                          dataMatchObj[1]["home_team"]
                        }}</span>
                      </div>
                      <div class="result-live text-center">
                        <div class="live-time">
                          <a
                            href=""
                            class="
                              btn btn-block btn-sm btn-danger
                              font-weight-bold
                            "
                          >
                            {{ dataMatchObj[1]["open_date"] }}</a
                          >
                          <a
                            href=""
                            class="
                              btn btn-block btn-sm btn-live-time
                              font-weight-bold
                            "
                            >{{ dataMatchObj[1]["open_time"] }}</a
                          >
                        </div>
                      </div>
                      <div class="away-team text-center">
                        <img src="../assets/img/soccer.png" alt="" />
                        <span class="team-title">{{
                          dataMatchObj[1]["away_team"]
                        }}</span>
                      </div>
                      <div class="modal-button">
                        <a href="" class="btn btn-sm btn-block handicap"
                          >{{ $t("home.goal-hand") }}
                          {{ dataMatchObj[1]["handicap"] }}</a
                        >
                        <a
                          @click="toggleModal"
                          :data-id="1"
                          href=""
                          class="btn btn-sm btn-block btn-live-modal mt-2"
                          >{{ $t("home.unravel") }}
                        </a>
                        <a
                          @click="toggleModalReal"
                          :data-id="1"
                          href=""
                          class="btn btn-sm btn-block btn-live-modal mt-2"
                          >{{ $t("home.real-order") }}
                        </a>
                      </div>
                    </div>

                    <div class="card-matches-footer">
                      <div class="left">
                        <a
                          target="_blank"
                          :href="whatsappLink"
                          style="color: white"
                        >
                          {{ $t("nav.click") }}
                          <img
                            src="../assets/img/whatsapp_green.png"
                            alt=""
                            style="
                              width: 30px;
                              height: 30px;
                              display: inline-block;
                            "
                          />
                          {{ $t("nav.recom") }} :
                          <span class="hightlight-text">{{
                            dataMatchObj[1]["free_analysis"]
                          }}</span>
                        </a>
                      </div>
                      <div class="right win"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container table-result">
          <div class="table-upcoming card-bg-blue">
            <div
              style="width: 100%"
              v-for="(item, index) in dataMatchObj"
              :key="index"
            >
              <div class="card-matches-title mt-3">{{ item.league_name }}</div>

              <div class="row table--4cols">
                <!-- First -->
                <div class="column-cell-1">
                  <div class="text-center">
                    <div class="live-time">
                      <a
                        href=""
                        class="btn btn-block btn-sm btn-danger font-weight-bold"
                      >
                        {{ item.open_date }}</a
                      >
                      <a
                        href=""
                        class="
                          btn btn-block btn-sm btn-live-time
                          font-weight-bold
                        "
                        >{{ item.open_time }}</a
                      >
                    </div>
                  </div>
                </div>
                <!-- Second -->
                <div class="column-cell-2">
                  <div class="flex">
                    <div class="home-team text-center">
                      <img src="../assets/img/soccer.png" alt="" />
                      <span class="team-title"> {{ item.home_team }}</span>
                    </div>
                    <div class="center-text">vs</div>
                    <div class="away-team text-center">
                      <img src="../assets/img/soccer.png" alt="" />
                      <span class="team-title"> {{ item.away_team }}</span>
                    </div>
                  </div>
                </div>
                <!-- Third -->
                <div class="column-cell-3">
                  <p>
                    <a
                      target="_blank"
                      :href="whatsappLink"
                      style="color: white"
                    >
                      {{ $t("nav.click") }}
                      <img
                        src="../assets/img/whatsapp_green.png"
                        alt=""
                        style="width: 40px; height: 40px; display: inline-block"
                      />
                      {{ $t("nav.recom") }} :
                      <span class="hightlight-text">{{
                        item.free_analysis
                      }}</span>
                    </a>
                  </p>
                </div>
                <!-- Fourth -->
                <div class="column-cell-4 mb-3">
                  <div class="modal-button">
                    <a href="" class="btn btn-sm btn-block handicap">
                      {{ $t("home.goal-hand") }} {{ item.handicap }}</a
                    >
                    <a
                      @click="toggleModalMatch"
                      :data-id="index"
                      href=""
                      class="btn btn-sm btn-block btn-live-modal mt-2"
                    >
                      {{ $t("home.unravel") }}
                    </a>
                    <a
                      @click="toggleModalMatchReal"
                      :data-id="index"
                      href=""
                      class="btn btn-sm btn-block btn-live-modal mt-2"
                    >
                      {{ $t("home.real-order") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <b-modal ref="my-modal" size="xl" hide-footer :title="$t('home.unravel')">
        <div class="col-lg-12 pb-2 alert fs-12">
          {{ currentUnravel }}
        </div>
        <button
          class="btn btn-info mt-2 mr-2"
          variant="outline-danger"
          block
          @click="hideModal"
        >
          {{ $t("nav.close") }}
        </button>
      </b-modal>

      <b-modal
        ref="my-modal-real-order"
        size="xl"
        hide-footer
        :title="$t('home.real-order')"
      >
        <div class="col-lg-12 pb-2 alert fs-12" v-if="currentRealOrder">
          {{ currentRealOrder }}
        </div>

        <table
          class="table"
          v-if="
            currentRealOrderDetail &&
            currentRealOrderDetail.real_order_home_team_score
          "
        >
          <tr>
            <td>{{ currentRealOrderDetail.real_order_handicap }}</td>
            <td>{{ currentRealOrderDetail.real_order_league }}</td>
            <td>
              {{ currentRealOrderDetail.home_team }} vs
              {{ currentRealOrderDetail.away_team }} (0:0) <br />{{
                currentRealOrderDetail.real_order_handicap
              }}
              <br />
              {{ currentRealOrderDetail.home_team }} -
              {{ currentRealOrderDetail.real_order_home_team_score }} @
              {{ currentRealOrderDetail.real_order_home_team_handicap }} (
              {{ $t("nav.finale") }} :
              {{ currentRealOrderDetail.real_order_home_team_score }}-
              {{ currentRealOrderDetail.real_order_away_team_score }})
            </td>
            <td>{{ currentRealOrderDetail.real_order_handicap_one }}</td>
            <td>{{ currentRealOrderDetail.real_order_handicap_two }}</td>
            <td>{{ currentRealOrderDetail.real_order_handicap_three }}</td>
            <td>
              <span
                v-if="
                  currentRealOrderDetail.real_order_handicap_win_or_lose ==
                  'win'
                "
                >{{ $t("nav.win") }}</span
              >
              <span
                v-if="
                  currentRealOrderDetail.real_order_handicap_win_or_lose ==
                  'draw'
                "
                >{{ $t("nav.draw") }}</span
              >
              <span
                v-if="
                  currentRealOrderDetail.real_order_handicap_win_or_lose ==
                  'lose'
                "
                >{{ $t("nav.lose") }}</span
              >
            </td>
          </tr>
        </table>

        <button
          class="btn btn-info mt-2 mr-2"
          variant="outline-danger"
          block
          @click="hideModal"
        >
          {{ $t("nav.close") }}
        </button>
      </b-modal>
      <Footer />
    </div>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import i18n from "../i18n";

export default {
  name: "Recommend",
  components: {
    "b-modal": BModal,
    Header,
    Footer,
  },
  props: {
    msg: String,
  },
  computed: {
    bannerTop() {
      if (this.bannerSettings != null) {
        return this.bannerSettings.filter(function (item) {
          return item.category == 1;
          //return item;
        });
      } //

      return "";
    },
    whatsappLink() {
      var text = "https://wa.me/";
      var number_locale = this.$i18n.locale == "vi" ? "_vi" : "";
      var number =
        this.matchSetting &&
        this.matchSetting["whatsapp_number" + number_locale]
          ? this.matchSetting["whatsapp_number" + number_locale]
          : "";
      return text + number;
    },
    dataMatchObj() {
      if (this.matchDetail) {
        // const today = new Date().getTime();
        const filterByExpiration = this.matchDetail.filter((items) => {
          // return (
          //   new Date(items.open_date + " " + items.open_time).getTime() > today
          // );
          return !items.home_team_score;
        });

        return filterByExpiration;
      }
      return [];
    },
    dataMatchResultObj() {
      if (this.matchDetail) {
        // const today = new Date().getTime();
        const filterByExpiration = this.matchDetail.filter((items) => {
          // return (
          //   new Date(items.open_date + " " + items.open_time).getTime() <= today
          // );
          return items.home_team_score;
        });

        return filterByExpiration;
      }
      return [];
    },
  },
  data() {
    return {
      i18n,
      active_class: "",
      limit: 2, //at most for match display
      matchDetail: null,
      matchResult: null,
      matchSetting: null,
      bannerHost: "",
      bannerSettings: [
        {
          title: "pc top",
          category: 1,
          status: 1,
          target_url: "https://978bet36.com/",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/5696d802b494e7f762dac27e22f08513.gif",
          width: 970,
          height: 150,
        },
        {
          title: "72963-pc",
          category: 1,
          status: 1,
          target_url: "https://77577.com",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/banner-77577-desktop.png",
          width: 970,
          height: 150,
        },
      ],
      currentUnravel: "",
      currentRealOrder: "",
      currentRealOrderDetail: "",
    };
  },
  created() {
    axios
      .get(`${this.bannerHost}/api/banner`)
      .then((response) => {
        const axiosBannerList = response.data.data;
        this.bannerSettings = axiosBannerList;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  mounted() {
    this.active_class = window.location.pathname.split("/")[2];
    //Api match setting

    axios
      .get(`/api/setting-detail`)
      .then((response) => {
        this.matchSetting = response.data.data;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
    //Api match detail
    axios
      .get(`https://72963.com/api/match-detail`)
      .then((response) => {
        this.matchDetail = response.data.data;
        this.matchResult = response.data.data;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  methods: {
    toggleModal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var handicap_name = this.$i18n.locale == "vi" ? "handicap_desc_vi" : "handicap_desc";
      var handicap_desc = this.dataMatchObj[id][handicap_name] != null && this.dataMatchObj[id][handicap_name] != ''? this.dataMatchObj[id][handicap_name]:this.dataMatchObj[id]['handicap_desc'];
      this.currentUnravel = handicap_desc;      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].show();
    },
    toggleModalReal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var real_order_name_default = 'real_order';
      var real_order_name = this.$i18n.locale == "vi" ? "real_order_vi" : "real_order";
                  console.log(real_order_name);

      var real_order = this.matchDetail[id][real_order_name] != null && this.matchDetail[id][real_order_name] != ''? this.matchDetail[id][real_order_name]:this.matchDetail[id][real_order_name_default];
      this.currentRealOrder = real_order;
      this.currentRealOrderDetail = this.matchDetail[id];
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal-real-order"].show();
    },
    toggleModalMatch(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var handicap_name = this.$i18n.locale == "vi" ? "handicap_desc_vi" : "handicap_desc";
      var handicap_desc = this.matchDetail[id][handicap_name] != null && this.matchDetail[id][handicap_name] != ''? this.matchDetail[id][handicap_name]:this.matchDetail[id]['handicap_desc'];
      this.currentUnravel = handicap_desc;
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].show();
    },
    toggleModalMatchReal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var real_order_name_default = 'real_order';
      var real_order_name = this.$i18n.locale == "vi" ? "real_order_vi" : "real_order";
      var real_order = this.matchResult[id][real_order_name] != null && this.matchResult[id][real_order_name] != ''? this.matchResult[id][real_order_name]:this.matchResult[id][real_order_name_default];
      this.currentRealOrder = real_order;
      this.currentRealOrderDetail = this.matchResult[id];
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal-real-order"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.$refs["my-modal-real-order"].hide();
    },
  },
};
</script>

<style scope>
</style>
